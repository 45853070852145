<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1>{{ $t('veranstalter_seite.uebersicht.label_veranstalterseite') }}</h1>
        </b-col>
        <b-col cols="auto" class="d-flex flex-row align-items-center">
          <b-button @click="logoutUser">
            {{ $t('veranstalter_seite.label_abmelden') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="8">
          <p>
            {{ $t('veranstalter_seite.uebersicht.info_veranstalterseite_1') }}
          </p>
          <p>
            {{ $t('veranstalter_seite.uebersicht.info_veranstalterseite_2') }}
          </p>
        </b-col>
        <b-col sm="4">
          <b-link to="benutzerdaten" class="btn btn-primary btn-block">{{ $t('veranstalter_seite.uebersicht.label_benutzerprofil') }}</b-link>
          <b-link :to="`/${$i18n.locale}/veranstalter/meine-events`" class="btn btn-primary btn-block">{{ $t('veranstalter_seite.uebersicht.label_meine_wettkaempfe') }}</b-link>
          <b-link :to="`/${$i18n.locale}/veranstalter/event-erstellen/formular`" class="btn btn-primary btn-block">{{ $t('veranstalter_seite.uebersicht.label_neue_veranstaltung_erfassen') }}</b-link>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import WettkampfListe from "./WettkampfListe";

export default {
  data() {
    return {
      user: false
    };
  },
  created() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      this.user = this.$store.getters["veranstalter/getUser"];
      if (!this.user) {
        this.logoutUser();
      }
    },
    logoutUser() {
      this.$store
        .dispatch("veranstalter/logout")
        .then(() => {
          console.log("Benutzer abgemeldet");
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.$router.push(`/${this.$i18n.locale}/veranstalter/event-erstellen`);
        });
    },
  },
};
</script>